.dashboard {
  margin: 1rem;
  display: flex;
}

.dashboard-content {
  max-width: 300px;
}

.dashboard-search-panel {
  max-width: 300px;
  background-color: #ccc;
  margin: 1rem;
}

.atleta-item {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  border: 1px solid #ccc;
  margin-bottom: 1rem;
}

.atleta-item-header {
  border: 1px solid black;
  padding: .4rem;
  display: flex;
  justify-content: space-between;
}

.atleta-item-name {
  margin: 0;
  padding-bottom: .4rem;
}

.atleta-item-header-titles {
  margin: 0;
  padding: 0;
  line-height: 6px;
  font-size: small;
}

.atleta-item-header-icons {
  display: flex;
  flex-direction: row;
  width: 32px;
  height: 32px;
}

.atleta-item-header-icons__image img{
  display: flex;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
}

.atleta-item-header-icons__image {
  display: flex;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
}

.atleta-item-box {
  display: flex;
  flex-direction: column;
}

.atleta-item-box p {
  font-size: small;
  margin: .2rem;
}

.atleta-item-header-icons__sex {
  min-width: 32px;
  height: 32px;
  border: 1px solid black;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
}

.blanca {
  background-color: white;
  width: 32px;
  height: 32px;
  border: 1px solid black;
}


.azul {
  background-color: blue;
  width: 32px;
  height: 32px;
  border: 1px solid black;
}


.violeta {
  background-color: purple;
  width: 32px;
  height: 32px;
  border: 1px solid black;
}


.marron {
  background-color: chocolate;
  width: 32px;
  height: 32px;
  border: 1px solid black;
}


.negra {
  background-color: black;
  width: 32px;
  height: 32px;
  border: 1px solid black;
}